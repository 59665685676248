.searchContainer {
    position: relative;

    .searchResults {
        position: absolute;
        width: 100%;
        background: white;
        z-index: 10000;
        overflow: auto;
        max-height: 250px;

        .resultItem {
            padding: 10px 15px;
            border-bottom: 1px solid gray;
            border-left: 1px solid gray;
            border-right: 1px solid gray;

        }
    }
}

.hidden {
    display: none;
}