.topBtns {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.clientCard {
    display: flex;
    flex-direction: column;

    &> div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;

        .icon {
            font-size: 32px;
            line-height: 0;
            margin-right: 5px;
        }
    }

    .startNavBtn {
        display: flex;
        align-items: center;


    }

}

.btnIcon {
    line-height: 0;
}

.clientMap {
    height: 250px;
    width: 100%;
}