.Auth {

    form {

        .btnFooter {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.test {
    height: 250px;
    width: 100%;
}