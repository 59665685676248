h4 {
    margin-top: 15px;
    margin-bottom: 10px;
}

.client-list {

}


.topBtns {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-icon {
    line-height: 0;
}


.client {
    .clientContainer {
        color: black;
        border: 1px solid black;
        padding: 10px 15px;
        margin-bottom: -1px;
        width: 100%;
        display: block;
        text-decoration: none;

        &:hover {
            background-color: lightgray;
            cursor: pointer;
        }
    }

}
